<template>
    <div class="">
        <v-card class="shadow-off" :loading="loading" :disabled="loading" loader-height="2">
            <v-card-text>
                <div class="d-flex align-center mb-4">
                    <v-btn small color="fbcPrimary" class="mr-1 white--text" v-if="$nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add')" @click="addForm">
                        <v-icon class="mr-1">mdi-plus</v-icon>
                        <span>New</span>
                    </v-btn>
                    <v-btn class="shadow-off" text @click="refreshData()" small
                        icon><v-icon>mdi-refresh</v-icon></v-btn>
                </div>
                <lb-datatablenew :headers="headers" :items="formatedData" :enableslot="['action']" :loading="loading" :bgColor="true">
                    <template v-slot:action="{ item }">
                        <div class="d-flex align-center justify-center">
                            <v-tooltip bottom content-class="tooltip-bottom" v-if="$nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add')">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon small v-bind="attrs" v-on="on" @click.stop="editThreshold(item)">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                <span>Edit</span>
                            </v-tooltip>
                            <v-tooltip bottom content-class="tooltip-bottom" v-if="!item.status">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" color="error">mdi-close-circle</v-icon>
                                </template>
                                <span> Disable</span>
                              </v-tooltip>
                              <v-tooltip bottom content-class="tooltip-bottom" v-else>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" color="success">mdi-check-circle</v-icon>
                                </template>
                                <span>Enable</span>
                            </v-tooltip>
                        </div>
                    </template>
                </lb-datatablenew>
            </v-card-text>
        </v-card>

        <lb-dailogboxnew v-model="locationdialog" :heading="locationdata._id ? 'Update Threshold' : 'Add Threshold'"
            width="600" :loading="locationdialogloading">
            <template v-slot:body>
                <div>
                    <v-row>
                        <v-col cols="12" class="my-0 py-1">
                            <lb-dropdown v-model="locationdata.name" :items="locationList" :label="$store.state.LocationHeader ? $store.state.LocationHeader  :'Location'"
                                :error="locationdataerror.location" itemtext="name" itemvalue="value"
                                :readonly="locationdata._id ? true : false"
                                :clearable="locationdata._id ? false : true" />
                        </v-col>

                        <v-col cols="12" class="my-0 py-1">
                            <lb-number v-model="locationdata.threshold" label="Threshold" min="0"
                                :error="locationdataerror.threshold" />
                        </v-col>
                    </v-row>
                </div>
            </template>
            <template v-slot:actions>
                <v-switch :label="enableSwitch === true ? 'Enable':' Disable'" class="mt-0" v-model="enableSwitch" @onchange="toggleSwitch(locationdata)" inset hide-details></v-switch>
                <v-spacer></v-spacer>
                <v-btn small color="btnColor" class="white--text">
                    <span v-if="locationdata._id" @click="updateThreshold(locationdata)">Update</span>
                    <span v-else @click="addThreshold()">Add</span>
                </v-btn>
            </template>
        </lb-dailogboxnew>
    </div>
</template>

<script>
export default {
    data() {
        return {
            right:'financialclose_master_threshold',
            locationdialog: false,
            locationdialogloading: false,
            locationdata: {},
            locationdataerror: {},
            loading: false,
            locationtypeItem: [],
            formatedData: [],
            locationListdata: [],
            locationList: [],
            locationtypedialog: false,
            headers: [
                {
                    value: "name",
                    text: "Name",
                    sortable: true,
                    filterable: true,
                    lock: true,
                    visible: false,
                },
                {
                    value: "threshold",
                    text: "Threshold",
                    sortable: false,
                    filterable: false,
                    datatype: "number",
                    default: "0",
                    lock: true,
                    visible: false,
                    alignment: "text-center",
                },
                {
                    value: "action",
                    text: "Action",
                    sortable: false,
                    datatype: "action",
                    lock: true,
                    visible: false,
                    alignment: "text-center",
                },
            ],
            listdata: [],
            enableSwitch:true,
        };
    },
    created() {
        this.refreshData();
    },
    activated() {
        this.refreshData();
    },
    methods: {
        refreshData() {
            this.projectId = this.$route.params.id || "";
            this.getData();
            this.formateData();
        },
        formateData() {
            let ObjectTemp = {};
            let ArrayTemp = [];
            this.listdata.forEach((item, index) => {
                ObjectTemp = {
                    __key: index,
                    __formatedkey: index,
                    _id: item._id,
                    indexno: index + 1,
                    name: item.locationName,
                    locationId: item.locationId,
                    threshold: item.threshold,
                    created_at: item.created_at,
                    updated_at: item.updated_at,
                    status: item.status,
                };
                ArrayTemp.push(ObjectTemp);
            });
            this.formatedData = ArrayTemp;
        },
        addForm() {
            this.locationdialog = true;
            this.locationdata = { threshold: 0 };
            this.locationdataerror = {};
            this.locationUserList = [];
            this.customassertions = false;
        },
        async getData() {
            this.loading = true;
            this.$store.commit("setProjectName", "");
            await this.axios
                .post("/v2/financialclose/masters/threshold/get")
                .then(async (ele) => {
                    if (ele.data.status === "success") {
                        this.listdata = ele.data.data;
                        await this.axios
                            .post("/v2/financialclose/location/get")
                            .then((ele) => {
                                if (ele.data.status === "success") {
                                    this.locationListdata = ele.data.data;
                                    this.locationListdata.map(item => this.locationList.push(item.name))
                                    this.listdata = this.listdata.filter(obj1 => {
                                        var location = []
                                        location = this.locationListdata.filter(obj2 => {
                                            if (obj2._id === obj1.location) {
                                                return obj2;
                                            }
                                        });
                                        obj1.locationName = location[0].name
                                        return obj1
                                    })
                                    this.formateData()
                                }
                            })
                    }
                })
                .catch((err) => {
                    this.$store.commit("sbError", err.message || err || "Unknown error!");
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        addThreshold() {
            this.locationdialogloading = true;
            let locationId = this.locationListdata.filter((item) => {
                if (item.name === this.locationdata.name) {
                    return item._id;
                }
            })
            this.locationdata.location = locationId[0]?._id || ""
            console.log(locationId)
            this.locationdata ={...this.locationdata, 'status':this.enableSwitch}
            this.axios
                .post("/v2/financialclose/masters/threshold/add", { data: this.locationdata })
                .then((ele) => {
                    if (ele.data.status === "success") {
                        this.locationdialog = false;
                        this.$store.commit("sbSuccess", ele.data.message || "Added Successfully");
                        this.refreshData();
                    } else {
                        if (ele.data.data.length > 0) {
                            if (ele.data.data[0].index0)
                                this.locationdataerror = ele.data.data[0].index0;
                        } else throw new Error(ele.data.message);
                    }
                })
                .catch((err) => {
                    this.$store.commit("sbError", err.message || err || "Unknown error!");
                })
                .finally(() => {
                    this.locationdialogloading = false;
                });
        },
        editThreshold(item) {
            this.locationdata = { ...item };
            this.locationdialog = true;
            this.enableSwitch = item.status
            this.locationdataerror = {};
            console.log(item);
        },
        updateThreshold(item) {
            this.locationdialogloading = true;
            item.status=this.enableSwitch
            this.axios
                .post("v2/financialclose/masters/threshold/edit/" + item._id, { data: item })
                .then((ele) => {
                    if (ele.data.status === "success") {
                        this.locationdialog = false;
                        this.$store.commit("sbSuccess", ele.data.mssage || 'Updated successfully');
                        this.refreshData();
                    } else {
                        if (ele.data.data.length > 0) {
                            if (ele.data.data[0].index0)
                                this.locationdataerror = ele.data.data[0].index0;
                        } else throw new Error(ele.data.message);
                    }
                })
                .catch((err) => {
                    this.$store.commit("sbError", err.message || err || "Unknown error!");
                })
                .finally(() => {
                    this.locationdialogloading = false;
                });
        },
        toggleSwitch(item){
            item.status=this.enableSwitch
        }
    },
};
</script>

<style></style>